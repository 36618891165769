import { components } from 'react-select';
import PropTypes from 'prop-types';
import Label from '@/storychief/components/Label';

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.shape({
    color: PropTypes.number,
  }),
};
const defaultProps = {
  data: null,
};

function SelectColorOption(props) {
  if (props.value) {
    return (
      <components.Option {...props}>
        <Label
          variant={props.data.color ? `color-coding--${props.data.color}` : 'color-coding--none'}
        >
          {props.label}
        </Label>
      </components.Option>
    );
  }
  return <span />;
}

SelectColorOption.propTypes = propTypes;
SelectColorOption.defaultProps = defaultProps;

export default SelectColorOption;
